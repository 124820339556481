.playground-popup {
  background-color: #1b1b1b;
  color: #a0a0a0;
  max-width: 548px;
  width: 100%;
  gap: 1rem;
  padding: 14px 4px;
}

.playground-popup h6 {
  font-weight: 700;
  font-size: 14px;
  text-align: left;
}

.playground-popup p {
  font-weight: 700;
  font-size: 12px;
  margin: 0px;
}

.pg-popup-box {
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 10px;
  border: 2px solid #333333;
  border-style: solid;
  border-radius: 4px;
  background-color: #262626;
}

.pg-popup-box h2 {
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .playground-popup {
    background-color: #1b1b1b;
    color: #a0a0a0;
    max-width: 356px;
    width: 100%;
    gap: 0.5rem;
    padding: 8px 1px;
  }
}

.game-custom-tooltip .tooltip-inner {
  text-align: center;
  /* max-width: 548px !important; */
  min-width: 548px !important;
  width: 100% !important;
  background-color: #1b1b1b !important ;
  font-family: "Nunito", sans-serif !important;
  border: 1px solid #283f4b;
  box-shadow: 0px 20px 40px #141414eb;
}

.game-custom-tooltip .tooltip-arrow {
  --bs-tooltip-bg: #1b1b1b;
}

.tooltip {
  --bs-tooltip-opacity: 2 !important;
}

/* div#button-tooltip {
  margin: 0 auto;
  left: 0 !important;
  right: 0 !important;
  display: block;
  width: 100%;
  text-align: center;
  transform: translate(35%, 252px) !important;
} */
