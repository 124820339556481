.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  background: #141414;
  position: relative;
  width: 100%;
  z-index: 9999;
  box-shadow: #0003 0 4px 6px -1px, #0000001f 0 2px 4px -1px;
  touch-action: none;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 35px;
}

.logo h2 {
  margin: 0;
}

.logo h2 a {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  margin: 0;
  display: block;
}

.links a {
  color: #a0a0a0;
  font-size: 15px;
  text-decoration: none;
  font-weight: 700;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header-buttons button:last-child {
  background: #1fff20;
  padding: 8px 20px;
  color: #141414;
}

.form-label {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 14px;
}
.header-buttons button {
  color: #a0a0a0;
  border: none;
  background: transparent;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  padding: 8px;
  border-radius: 8px;
}

/* .header-buttons button:hover {
  background-color: #262626;
  color: #fff;
  transition: 0.4s ease;
} */

.wallet-body {
  display: flex;
  gap: 10px;
  background-color: #262626;
  width: fit-content;
  align-items: center;
  padding: 4px 4px;
  border-radius: 5px;
}

.wallet-body p {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  margin: 0 9px;
}

.wallet-body button, .wallet-body button:hover, .wallet-body button:focus, .wallet-body button:focus:not(:focus-visible) {
  height: 35px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px!important;
}

/* header profile  */

.profile-section {
  display: flex;
  align-items: center;
  gap: 5px;
  max-width: 294px;
  width: 100%;
  justify-content: flex-end;
}

.profile-section svg {
  width: 30px;
  height: 30px;
}

.profile-section .dropdown-toggle::after {
  border: 0.3em solid #a0a0a0;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.profile-section p {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: #a0a0a0;
}

.profile-section img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}

.profile-section .dropdown-toggle,
.profile-section .dropdown-toggle:focus:not(:focus-visible) {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 2px;
}

.profile-section .dropdown-menu {
  background: #333333;
}

.profile-section .dropdown-item {
  color: #a0a0a0;
  font-size: 15px;
  text-decoration: none;
  font-weight: 700;
}

.profile-section .dropdown-item:hover,
.profile-section .dropdown-item:focus,
.profile-section .dropdown-item:active {
  background: #141414;
  color: #a0a0a0;
}

.active-menu {
  color: #fff !important;
  font-weight: 500;
}
