main.ant-layout-content .plinko {
  padding-top: 50px;
  padding-bottom: 50px;
  color: #ffffff;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.plinko_tab {
  padding: 1rem !important;
  background-color: #263742;
  border-radius: 6px 0px 0px 6px;
}

.dice-history .table-dark.table-striped tbody tr td:last-child {
  color: #fdf401;
}

.stats-top-status {
  text-align: center;
}

.stats-top-status p {
  margin: 0;
  text-align: center;
}

.table > :not(caption) > * > * {
  padding: 17px 26px !important;
}

.dice-history .table-dark.table-striped tbody tr td:first-child,
.dice-history .table-dark.table-striped tbody tr td:nth-child(2) {
  color: white;
}

.dice-history .table-bordered td {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  vertical-align: middle;
  color: #a0a0a0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  color: #a0a0a0;
}

.plinko > .ant-row {
  display: flex;
}

.plinko_tab .ant-tabs-bar {
  border-bottom: navajowhite;
}

.plinko_tab .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-nav {
  width: 100%;
}

.plinko_tab
  .ant-tabs-bar
  .ant-tabs-nav-scroll
  .ant-tabs-nav
  div.ant-tabs-ink-bar {
  display: none !important;
}

.plinko_tab.ant-tabs .ant-tabs-small-bar .ant-tabs-tab {
  font-weight: 600;
  font-size: 0.8em;
  touch-action: manipulation;
  position: relative;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  line-height: 1.5;
  color: rgb(183, 191, 214);
  transition: background 300ms ease 0s, opacity 300ms ease 0s,
    transform 100ms ease 0s;
  border-radius: 5px;
  background: rgb(46, 68, 82);
  padding: calc(0.5em + 2px) 1em;
  margin-right: 0px;
  width: 50%;
}

.plinko_tab.ant-tabs .ant-tabs-top-bar .ant-tabs-tab.ant-tabs-tab-active {
  background-color: rgb(21, 33, 45);
}

.plinko_tab.ant-tabs .ant-tabs-top-bar .ant-tabs-tab.ant-tabs-tab-active:hover {
  background-color: rgb(21, 33, 45);
}

.plinko_tab.ant-tabs .ant-tabs-nav div .ant-tabs-tab:first-child {
  border-radius: 6px 0px 0px 6px;
}

.plinko_tab.ant-tabs .ant-tabs-nav div .ant-tabs-tab:last-child {
  border-radius: 0px 6px 6px 0px;
}

.manual_tab label {
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(183, 191, 214);
  font-size: 1em;
  font-weight: 600;
  letter-spacing: 0px;
  margin: 0px 0px 0.25em;
  transition: all 200ms ease-out 0s;
}

.total_bet .ant-select {
  width: 100%;
}

.total_bet .ant-select .ant-select-selection {
  background-color: transparent;
  border: navajowhite;
  color: #ffffff;
  font-weight: 600;
}

.total_bet .ant-select .ant-select-arrow {
  color: #ffffff;
}

.ant-select-dropdown-menu {
  background-color: #15212d;
}

.ant-select-dropdown-menu .ant-select-dropdown-menu-item-selected {
  background-color: #0000;
  font-weight: 400;
}

.ant-select-dropdown-menu li.ant-select-dropdown-menu-item {
  color: #ffffff;
  font-size: 14px;
}

.ant-select-dropdown-menu .ant-select-dropdown-menu-item-active {
  background-color: #007bff !important;
}

.total_bet .ant-select.ant-select-focused {
  background-color: #15212d;
}

div#plinko canvas {
  max-width: 100%;
  height: auto;
}

div#plinko {
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  margin-top: -30px;
}

.plinko_tab_sidepanel {
  display: flex !important;
  flex-direction: column;
  position: relative;
}
.tab-section {
  padding: 25px;
}
.pegtexthigh {
  /* font-size: 9px !important;
  font-weight: 700 !important; */
}

.plinko-pegs {
  padding-top: 40px;
}

.plinko-pegs-row {
  height: 2em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.plinko-peg {
  grid-row: 1 / auto;
  font-size: 14px;
  background: #fff !important;
  border: 2px solid #b9a11e;
}

i.bi.bi-infinity {
  position: absolute;
  /* top: 50%; */
  bottom: 8px;
  right: 15px;
}
.linkTxt {
  font-weight: 600;
  font-size: 15px;
}
.labelTxt {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #a0a0a0 !important;
}

@keyframes anim-popoutin {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  25% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.plinko_pegs_wrapper {
  display: grid;
  height: 100%;
  position: relative;
  gap: 1% 1%;
  width: 75%;
}

.plinko-.pegs {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 5px;
}

.plinko-peg1 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg2 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg3 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg4 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg5 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg6 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg7 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg8 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg9 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg10 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg11 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg12 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg13 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg14 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg15 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg16 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.plinko-peg17 {
  position: relative;
  text-align: center;
  border-radius: 0.3em;
  background: #b9a11e;
}

.pegs {
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: -5px;
  max-width: 800px;
  width: 100%;
  margin: 0px auto;
  top: -15px;
  position: relative;
}

.pegs_wrapper {
  display: grid;
  height: 100%;
  position: relative;
  grid-gap: 1% 1%;
  gap: 1% 1%;
  width: 90%;
}

.plinko-pegtext {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 700;
  color: #b9a11e;
  animation: 2s anim-popoutin ease infinite;
  transition: all 0.5s;
}

.peg1 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-width: 0.5px;
  border-color: rgb(211, 211, 211);
  border-style: solid;
  border-radius: 5px;
  background-color: rgb(255, 0, 63);
}

.peg2 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 24, 55);
}

.peg3 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 48, 47);
}

.peg4 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  background-color: rgb(255, 72, 39);
  border-color: rgb(211, 211, 211);
}

.peg5 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 96, 32);
}

.peg6 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 120, 24);
}

.peg7 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 144, 16);
}

.peg8 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 168, 8);
}

.peg9 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 192, 0);
}

.peg10 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 168, 8);
}

.peg11 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 144, 16);
}

.peg12 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 120, 24);
}

.peg13 {
  position: relative;

  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 96, 32);
}

.peg14 {
  position: relative;

  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 72, 39);
}

.peg15 {
  position: relative;

  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 48, 47);
}

.peg16 {
  position: relative;

  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 24, 55);
}

.peg17 {
  position: relative;

  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 0, 63);
}

.peg0 {
  position: relative;
  text-align: center;
  transition: all 0.2s;
  border-radius: 0.3em;
  border-color: rgb(211, 211, 211);
  background-color: rgb(255, 192, 0);
}

.pegtext {
  position: absolute;
  /* top: 0px; */
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
}

.peg {
  grid-row: 1 / auto;
  font-size: 14px;
  cursor: pointer;
  color: rgb(255, 255, 255);
  border-width: 0.5px;
  border-color: transparent;
  border-style: solid;
  border-radius: 5px;
  height: 38px;
  clip-path: polygon(50% 50%, 100% 30%, 100% 100%, 0 100%, 0 30%);
}

.peg span {
  color: #141414;
  font-size: 11px;
  font-weight: 900;
}

.canvas-container {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0px 20px 0px;
  /* padding-bottom: 50px; */
  position: relative;
  height: calc(100% - 0px);
}

.ant-btn.bet-btn {
  max-width: 350px !important;
}

.plinko-place-bet {
  width: 100%;
}

.plinko-place-bet label {
  color: #ddd;
  padding: 10px 0px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: block;
  text-align: left;
}

.multiplier-btn {
  display: flex;
  align-items: center;
  background: #333333;
  position: absolute;
  top: 24px;
  right: 2px;
}

.total_bet_input {
  width: 100%;
  padding-top: 0px;
  position: relative;
  margin-bottom: 10px;
}
.total_bet > div:first-child {
  padding-top: 0px;
}
.total_bet_input input {
  width: 100%;
  border-width: 2px;
  border-color: #333333 !important;
  border-style: solid;
  border-radius: 4px;
  background-color: #262626 !important;
  color: #ffffff;
  padding: 8px 15px;
  outline: none;
  font-size: 14px;
  font-weight: 700;
}

.total_bet_input input::placeholder {
  color: #ccc;
}

.total_bet_input input:focus {
  color: #fff !important;
}

.bottom_total_bet .MuiInput-formControl {
  color: rgb(255, 255, 255);
  height: 2.25rem;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  background: transparent;
  border: 1px solid #51525f;
}

.bottom_total_bet .MuiSelect-icon {
  color: #ffffff !important;
}

.plinko-place-bet .bottom_total_bet label {
  z-index: 9;
}

.risk_bet {
  width: 28%;
}

.bottom_total_bet {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 20px;
}

.row_bet {
  width: 28%;
}

.number_bet_input {
  width: 28%;
}

.auto_bet_input {
  width: 10%;
}

.MuiFilledInput-underline:after,
.MuiInput-underline:after {
  border-bottom: 2px solid transparent !important;
}

.MuiFormLabel-root.Mui-focused {
  color: #ffffff !important;
  font-size: 18px !important;
}

.risk-select {
  font-size: 18px !important;
  top: -15px !important;
}

.plinko-logo-containor {
  position: relative;
}

.plinko-logo-containor {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 25px;
}

.plinko-lv-logo img {
  width: 100px;
}

.bets-table {
  padding: 0px 16px 16px;
  max-height: 90vh;
  overflow: auto;
  height: 100%;
}

.bets-table .MuiTableContainer-root {
  border: 1px solid #2a2b37 !important;
}

.bets-table .MuiTableCell-root {
  font-family: "Poppins", sans-serif !important;
}

.btn-gold.MuiButton-contained.Mui-disabled {
  color: #fff !important;
  pointer-events: unset !important;
  cursor: not-allowed !important;
}

.drop-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.drop-btn button {
  padding: 8px 20px;
}

.selected-peg {
  border: 2px solid #ffffff;
  box-shadow: rgb(255 255 255) 0px 0.2em 0px 0px;
}

.main_section {
  border-radius: 8px;
}

.plinko_tab_sidepanel {
  border-radius: 0;
  background-color: #1b1b1b;
  max-width: 300px;
  width: 100%;
  padding: 0px;
}

.plinko-grid {
  display: flex;
  border-radius: 12px 12px 0px 0px;
  position: relative;
  z-index: 999;
  background: #262626;
  overflow: hidden;
}

.main_section {
  padding: 40px 20px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  /* max-width: calc(100% - 300px); */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.fairness-popup.plinko-game-page.login-popup.modal {
  position: absolute;
  margin: 0;
}

.plinko-game-page {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  /* min-height: 100vh; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 40px;
  margin-bottom: 20px;
}

.plinko-bet-btn,
.plinko-bet-btn:hover,
.plinko-bet-btn:focus,
.plinko-bet-btn:active,
.plinko-bet-btn:first-child:active,
.plinko-bet-btn button:focus:not(:focus-visible) {
  background-color: #1fff20 !important ;
  border: 1px solid transparent !important;
  color: #141414 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 10px !important;
  border-radius: 4px !important;
}

.total_bet_input p {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 14px;
}

.auto-bet-section {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  margin-top: 15px;
}

.place-bet-section {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.auto-bet p {
  margin-bottom: 5px;
}

.auto-bet .toggle-section label {
  margin-left: 0px;
}

.no-of-rows .dropdown {
  width: 100%;
}

.no-of-rows {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 10px;
}

.no-of-rows .dropdown button,
.no-of-rows .dropdown button:hover,
.no-of-rows .dropdown button:focus,
.no-of-rows .show > .btn-success.dropdown-toggle {
  position: relative;
  width: 100%;
  border-width: 2px;
  border-color: #333333;
  border-style: solid;
  border-radius: 4px;
  background-color: #262626;
  color: #ffffff;
  padding: 8px 15px;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
}

.no-of-rows .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  position: absolute;
  right: 15px;
  top: calc(50% - 1px);
}

.no-of-rows .dropdown-menu {
  background-color: #262626;
}

.no-of-rows .dropdown-item {
  /* color: #a0a0a0; */
  color: #a0a0a0;
}

.no-of-rows .dropdown-item:hover,
.no-of-rows .dropdown-item:focus {
  background-color: #333333;
  color: #fff !important;
}

.no-of-rows .dropdown p {
  margin-bottom: 2px;
  font-weight: 700;
  font-size: 14px;
}

.auto-bet p {
  font-size: 14px;
  color: #a0a0a0;
}

.pegs.rows16 .pegs_wrapper,
.pegs.rows15 .pegs_wrapper,
.pegs.rows14 .pegs_wrapper,
.pegs.rows13 .pegs_wrapper {
  width: 93%;
}

.multiplier-btn button,
.multiplier-btn button:focus:not(:focus-visible) {
  color: #fff;
  font-weight: 700;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 0;
  border-color: transparent !important;
  background: transparent !important;
  border-left: 1px solid #3c3d3d !important;
  outline: none;
}

.multiplier-btn button:first-child {
  border-left: 1px solid transparent !important;
}

/* .multiplier-btn button:hover,
.multiplier-btn button:focus {
  background: rgb(28 33 52 / 55%);
  border-color: rgb(28 33 52 / 55%);
  color: #fff !important;
} */

/********* toggle ************/
.toggle-section input {
  height: 0;
  width: 0;
  visibility: hidden;
  position: absolute;
}

.toggle-section label {
  cursor: pointer;
  text-indent: -9999px;
  width: 50px;
  height: 25px;
  display: block;
  border-radius: 100px;
  position: relative;
  margin-bottom: 0px;
  margin-left: auto;
  border-radius: 30px;
  background-color: #333333;
}

.toggle-section label:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 17px;
  height: 17px;
  background: #5c5c5c;
  border-radius: 50%;
  transition: 0.5s;
}

.toggle-section input:checked + label {
  background-color: #1fff20;
  /* margin-bottom: 10px; */
}

.toggle-section input:checked + label:after {
  left: calc(100% - 4px);
  transform: translateX(-100%);
  background: #1b1b1b;
}

.toggle-section label:active:after {
  width: 6px;
}

.toggle-section.dark-toggle input:checked + label {
  background: rgb(31 35 48);
}

.toggle-section.dark-toggle input:checked + label:after {
  background-color: rgb(81, 226, 245);
}

.toggle-section input {
  display: none;
}

.volume-btn {
  position: absolute;
  right: 0px;
  z-index: 999999;
}

.total_bet {
  position: relative;
}

.volume-btn i {
  color: #fdf401;
  font-size: 25px;
  cursor: pointer;
}

/* .plinko-game-page:before {
  content: "";
  background: rgb(0 0 0 / 81%);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: -1px;
} */

/* .plinko-game-page .container {
  max-width: 100% !important;
  padding: 0;
} */

.toggle-section {
  display: flex;
  justify-content: flex-end;
}

.resultStatsbar {
  position: absolute;
  top: 28%;
  right: 0%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 240px;
  height: 100%;
  width: 48px;
  overflow: hidden;
}

.resultStatsbar p {
  margin: 0px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  border-color: rgb(253 218 7);
  background-color: rgb(254 192 12);
  padding: 6px 4px;
  border-radius: 6px;
  text-align: center;
  filter: brightness(0.2);
  /* position: absolute; */
  width: 100%;
  opacity: 0;
  animation-duration: 0.5s;
}

.resultStatsbar p:first-child {
  filter: brightness(1.2);
  top: 0px;
  opacity: 1;
  /* animation: sizeHeight 0.5s ease forwards; */
  /* height: 36px; */
  /* overflow: hidden; */
}

/* .resultStatsbar p.animate__animated.animate__slideInDown {
  filter: brightness(1.2);
  top: 0px;
  opacity: 1;
  animation: sizeHeight 0.5s ease forwards;

  overflow: hidden;
  display: block;
} */

@keyframes sizeHeight {
  from {
    height: 0px;
  }
  to {
    height: 36px;
  }
}

.resultStatsbar p:nth-child(2) {
  filter: brightness(1);
  top: 40px;
  opacity: 0.9;
}

.resultStatsbar p:nth-child(3) {
  filter: brightness(0.8);
  top: 80px;
  opacity: 0.8;
}

.resultStatsbar p:nth-child(4) {
  filter: brightness(0.6);
  top: 120px;
  opacity: 0.7;
}

.resultStatsbar p:nth-child(5) {
  filter: brightness(0.4);
  top: 160px;
  opacity: 0.6;
}

.resultStatsbar p:nth-child(6) {
  filter: brightness(0.4);
  opacity: 0.4;
  top: 200px;
}

.gameResultpopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: #333333;
  border-radius: 8px;
  min-width: 100px;
  box-shadow: 0px 0px 10px 6px #0d1322;
  padding: 10px 20px;
}

.gameResultpopup p {
  font-size: 30px;
  font-weight: 800;
  transform-origin: 50% 100%;
  position: relative;
  transform-style: preserve-3d;
  margin-bottom: 0px;
  color: #66c86a;
}
.gameResultpopup p.win {
  color: #66c86a;
}
.gameResultpopup p.lose {
  color: #f44336;
}
@keyframes scaling {
  20% {
    text-shadow: 0px 0 #f4bf27, 0px 0 #ffc107, 0px 0 #fdf401;
    transform: scale(0.9, 1) rotatey(-10deg);
  }

  40% {
    transform: scale(0.9, 2) translatey(16px);
    text-shadow: -10px 0px #f4bf27, -20px 0 #ffc107, -30px 0 #fdf401;
  }

  60% {
    transform: scale(0.9, 1) rotatey(-5deg);
    text-shadow: 0px 0 #f4bf27, 0px 0 #ffc107, 0px 0 #fdf401;
  }

  80% {
    transform: scale(0.9, 2) translatey(16px);
    text-shadow: 10px 0px #f4bf27, 20px 0 #ffc107, 30px 0 #fdf401;
  }
}

@keyframes jump {
  20% {
    transform: translatey(0) scale(0);
    opacity: 0;
  }

  40% {
    transform: translatey(-50px) scale(1);
    opacity: 1;
  }

  60% {
    transform: translatey(0) scale(0);
    opacity: 0;
  }

  80% {
    transform: translatey(-50px) scale(1);
    opacity: 1;
  }
}
.loss-amt {
  color: red !important;
  font-weight: 600 !important;
}
td.loss-amt {
  color: red !important;
  font-weight: 600 !important;
}
td.win-amt {
  color: green !important;
  font-weight: 600 !important;
}

/* @media screen and (max-width: 1366px) {
  .plinko_tab_sidepanel {
    max-width: 30%;
  }

  .main_section {
    max-width: 70%;
  }
} */

@media screen and (max-width: 991px) {
  .plinko_tab_sidepanel {
    max-width: 30% !important;
  }

  .plinko-grid {
    flex-direction: inherit !important;
  }

  .resultStatsbar {
    max-height: 190px;
  }

  .resultStatsbar p {
    font-size: 12px;
  }
  .profile-section {
    max-width: inherit;
    width: auto;
}

}

@media screen and (max-width: 768px) {
  .plinko-bet-btn {
    width: 100%;
  }

  .resultStatsbar p {
    font-size: 8px;
  }

  .resultStatsbar {
    top: 22%;
    max-height: 100px;
  }

  .total_bet {
    row-gap: 20px;
  }

  .risk_bet,
  .row_bet,
  .number_bet_input,
  .auto_bet_input {
    width: 48%;
  }

  .peg {
    font-size: 12px;
    height: 25px;
  }

  .pegs_wrapper {
    height: 77%;
  }

  .plinko-lv-logo img {
    width: 80px;
  }

  .main_section {
    max-width: 100%;
  }

  .plinko_tab_sidepanel {
    max-width: 100%;
    min-height: fit-content;
  }

  .peg span {
    color: #141414;
    font-size: 11px;
    font-weight: 900;
  }
  .auto-bet-section .auto-bet {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .links a {
    display: none;
  }
  .App {
    overflow-x: hidden;
  }
  .plinko-game-page {
    margin-left: 0;
    margin-top: 15px;
  }
  .header {
    padding: 0 12px !important;
  }
  .plinko-sidepanel {
    z-index: 9999;
    border-left: 1px solid #29424f;
  }
}
@media screen and (max-width: 768px) {
  .pegs_wrapper {
    height: 72%;
  }

  .plinko-lv-logo {
    top: 15px;
    left: 16px;
  }

  .plinko_tab_sidepanel {
    max-width: 100% !important;
    height: auto !important;
  }
  .tab-section {
    padding: 25px 25px 15px;
  }
  .plinko-game-footer {
    padding: 10px 25px !important;
  }

  .plinko-grid {
    flex-direction: column-reverse !important;
  }

  .resultStatsbar-section {
    width: 30px !important;
  }

  .resultStatsbar-section p {
    font-size: 12px !important;
    padding: 3px 4px !important;
  }

  .canvas-container {
    padding: 0 5px;
  }
  .main_section,
  .canvas-container {
    height: fit-content;
    padding-bottom: 10px;
  }

  .multiplier-btn {
    flex-wrap: inherit;
  }

  .pegs.rows16 .pegs_wrapper {
    width: 95%;
  }

  .peg {
    border-radius: 4px;
  }

  .plinko-place-bet .btn {
    /* height: 35px; */
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .total_bet_input {
    width: 100%;
  }

  .total_bet_input .MuiInputBase-input {
    width: 100%;
  }

  .plinko-bet-btn {
    width: unset;
  }

  .pegs_wrapper {
    height: 60%;
  }
}

@media screen and (max-width: 400px) {
  .pegs.rows16 .pegs_wrapper {
    width: 100%;
    margin: 0 auto;
  }

  .plinko-bet-btn {
    width: 100%;
  }

  .plinko-lv-logo img {
    width: 50px;
  }

  .pegs_wrapper {
    height: 50%;
  }

  .peg {
    font-size: 7px;
    width: 15px;
    height: 15px;
  }

  .peg span {
    font-size: 7px;
  }
  .rows10 .peg,
  .rows8 .peg,
  .rows9 .peg {
    width: 20px !important;
  }
  /* .rows11 .peg,.rows12 .peg {
  width: 20px !important;
} */
  .rows8 .pegs_wrapper,
  .rows9 .pegs_wrapper,
  .rows10 .pegs_wrapper,
  .rows11 .pegs_wrapper,
  .rows12 .pegs_wrapper {
    justify-content: space-around;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 800px !important;
    margin-right: auto;
    margin-left: auto;
  }
}

/* footer */

.slot-game-footer {
  height: 60px;
  border-top: 1px solid #0d1322;
  background: #0d1322;
  padding: 10px;
  width: 100%;
}

.plinko-game-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  padding: 0;
  cursor: pointer;
}

.fairness-btn {
  color: #a0a0a0;
  font-size: 14px;
  gap: 4px;
  font-weight: 700;
  cursor: pointer;
}
.fairness-btn span {
  display: inline-block;
  line-height: initial;
}

.fairness-btn img {
  width: 16x;
  height: 16px;
  border-radius: 100%;
}

.fairness-popup .modal-dialog {
  max-width: 500px !important;
}

.fairness-popup .modal-title {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 18px;
}

.tab-box label.form-label {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #a0a0a0 !important;
}

.fairness-popup .modal-content {
  background: #1b1b1b;
  color: #fff;
}

.fairness-popup .modal-header {
  border-bottom: none;
  padding: 20px 20px;
}
.varify-content {
  padding: 0px 20px;
}

.plinko-change .btn,
.plinko-change .btn:hover,
.plinko-change .btn:focus,
.plinko-change .btn:focus:not(:focus-visible) {
  height: 100%;
  background: #1fff20;
  border-color: #1fff20;
  border-radius: 0px 3px 3px 0px;
  margin-right: -2px;
  font-size: 14px;
  color: #141414 !important;
  font-weight: 800;
}
.mb-10px {
  margin-bottom: 10px;
}

.plinko-game-page .fainess-popup-content .nav-tabs {
  background: #262626;
  border-radius: 30px;
  min-height: 57px;
  border: none !important;
  margin-bottom: 10px !important;
  padding: 5px;
  margin: 0px 20px;
}

.seed-bottom h4 {
  font-size: 15px;
  text-align: center;
}
.seeds-content form {
  padding: 0px 20px;
}
.seed-bottom {
  background: #262626;
  padding: 20px;
}
.fairness-popup .modal-content {
  width: 100%;
  padding: 0;
}
.plinko-input.plinko-nobg-input {
  display: flex;
  align-items: stretch;
}
/* .fainess-popup-content {
  padding-top: 15px;
  padding-bottom: 30px;
} */
.plinko-tab .nav-tabs {
  border: none;
}

.plinko-tab .nav-tabs .nav-link:hover,
.plinko-tab .nav-tabs .nav-link:focus {
  border: none;
  outline: none;
  margin: 0;
}

/* .plinko-tab .nav-tabs .nav-link.active {
  border: none;
  outline: none;
  margin: 0;
  background: #191e2b !important;
  width: 100%;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05) !important;
  color: #ffffff !important;
}

.plinko-tab .nav-tabs .nav-link {
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.05) !important;
  border: none;
  font-weight: 500;
  padding: 12px 50px;
  color: #a0a0a0 !important;
  width: 100%;
} */

/* .fairness-popup .modal-body {
  padding: 0px 20px 20px 20px;
} */
.modal-backdrop.show {
  background: rgb(0 0 0 / 50%) !important;
}
.fairness-popup .tab-content > .active {
  display: block;
}

.tab-box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 0;
}

.fainess-popup-content .plinko-tab {
  padding: 0;
  max-width: 100%;
  margin: 0 auto;
  border: none;
  height: 100%;
}

.plinko-tab {
  max-width: 342px;
  width: 100%;

  border-radius: 6px 0px 0px 6px;
  padding: 25px;
  position: relative;
}

.plinko-tab .tab-content > .active {
  display: block;
}

.plinko-tab .tab-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 0 0;
}

.plinko-tab .form-group {
  position: relative;
}

.plinko-tab .tab-box .form-group {
  margin: 0;
}

.plinko-input {
  position: relative;
}

.plinko-input .form-control,
.plinko-input .form-control:focus {
  background: #262626;
  border: 2px solid #333333;
  box-sizing: border-box;
  border-radius: 3px;
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: #a0a0a0;
  height: 47px;
  position: relative;
  padding-right: 75px;
}

.plinko-input .plinko-input-btn {
  position: absolute;
  top: 2px;
  right: 2px;
}

.plinko-input .plinko-input-btn button,
.plinko-input .plinko-input-btn button:focus,
.plinko-input
  .plinko-input-btn
  .btn-primary:not(:disabled):not(.disabled):active {
  height: 43px;
  width: 50px;
  background: #333333;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.plinko-input .plinko-input-btn button img {
  width: 20px;
}

.varify-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  min-height: 200px;
  border: 1px dotted #fff;
  border-radius: 12px;
}

.tab-box-game {
  display: flex;
  flex-direction: column;
}

.fairness-popup .btn-close:focus {
  box-shadow: none !important;
}

.tab-box-game select {
  color: #a0a0a0;
  height: 47px;
  padding: 5px;
  box-shadow: none !important;
  outline: none !important;
  font-size: 14px;
  background: #262626;
  border: 2px solid #333333;
  box-sizing: border-box;
  border-radius: 3px;
}

.plinko-input .arrow-bottom {
  right: 50px;
}

.plinko-input .arrow-bottom button img {
  transform: rotate(90deg) !important;
}

.plinko-input .plinko-input-btn button img {
  width: 20px;
}

.plinko-input .arrow-top button img {
  transform: rotate(-90deg) !important;
}

.plinko-input .plinko-input-btn button img {
  width: 20px;
}

.varify-bottom-text {
  padding: 20px 0 0;
  text-align: center;
  cursor: pointer;
  transition: all 0.5s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.varify-bottom-text:hover {
  color: #a0a0a0;
}

.final_result {
  display: flex;
  align-items: center;
  justify-content: center;
}

.varify-content {
  margin-top: 15px;
}

.live-stats-heading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}

.live-stats-heading {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
}

.dice-history .table-dark thead th {
  background: #0d1322 !important;
  border-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 26px;
  color: #a0a0a0;
}

.tbody_slot {
  min-height: 0px !important;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-thumb {
  background-color: #333333;
}

*::-webkit-scrollbar-track {
  background-color: #222222;
}

.plinko-place-bet::-webkit-scrollbar {
  width: 4px;
  height: 4px !important;
}

.plinko-place-bet::-webkit-scrollbar-thumb {
  background-color: #242936;
}

.plinko-place-bet::-webkit-scrollbar-track {
  background-color: #1f2330;
}

table.table.table-dark.table-striped.table-bordered tr {
  border: none;
}

.dice-history .table-dark.table-striped tbody tr:nth-of-type(odd) {
  background: #242936 !important;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: #1a2030 !important;
}

.table > :not(caption) > * > * {
  background-color: #0d1322 !important;
  border-color: #0d1322 !important;
}

table td {
  font-size: 14px;
  vertical-align: middle;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  /* background: transparent url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e) center/1em auto no-repeat; */
  border: none !important;
  outline: none;
  border-radius: 0.375rem;
  opacity: 0.5;
}

.login-popup .btn-close {
  background-image: url(../../assets/images/game/iconcross.svg);
}

/* .btn-close::before {
  content: "";
  font-size: 30px;
  width: 40px !important;
  background: transparent;
  height: 43px !important;
  position: absolute;
  top: 7px;
  bottom: 0;
  right: 8px;
  color: #ffffff;
  font-weight: 700;
  background-image: url(../../assets/images/game/iconcross.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px 20px;

} */

.btn-close :hover,
.btn-close :focus {
  border: none !important;
}

.testmode p {
  margin: 0px;
}

.testmode {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.auto-bet-mode {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 10px;
}

.auto-bet-mode p {
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
}

.volume-btn1 i {
  cursor: pointer;
}
.statsChart {
  background: #0d1322;
  border-radius: 6px;
  margin-bottom: 30px;
}

.calcution-btn {
  background-color: #fdf401 !important;
  border: none !important;
  border-radius: 6px !important;
  box-shadow: 0 0 10px rgba(255, 176, 25, 0.4),
    inset 0 1px 0 hsla(0, 0%, 100%, 0.2), inset 0 -3px 0 rgba(0, 0, 0, 0.15),
    inset 0 0 15px #ff8719 !important;
  color: #081c30 !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 22px !important;
  /* width: 100% !important; */
  padding: 12px 20px !important;
  text-transform: uppercase !important;
  /* height: 46px !important; */
}
/* button disabled */

.multiplier-btn .btn:disabled {
  pointer-events: all !important;
  cursor: not-allowed !important;
  background: #1a2030;
}

.auto-bet .toggle-section input:checked + label.disabled,
.toggle-section input:checked + label.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: all;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  outline: none !important;
}
.plinko-place-bet .btn {
  outline: none !important;
}

.la-volume-up:before {
  content: "\f028";
  font-size: 24px;
  color: #a0a0a0;
}
.la-volume-off:before {
  content: "\f026";
  font-size: 24px;
  color: #a0a0a0;
}

li.nav-item {
  width: 50%;
}
.nav {
  background: #0d1322;
  border-radius: 4px;
}

/* Base styles for the slider */
input[type="range"].styled-slider {
  appearance: none;
  width: 100%;
  height: 8px;
  background: #142834;
  border-radius: 5px;
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
}

input[type="range"].styled-slider:hover {
  opacity: 1;
}

/* Track styles */
input[type="range"].styled-slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #262626;
  border-radius: 5px;
}

input[type="range"].styled-slider::-moz-range-track {
  width: 100%;
  height: 8px;
  background: #262626;
  border-radius: 5px;
}

input[type="range"].styled-slider::-ms-track {
  width: 100%;
  height: 8px;
  background: #262626;
  border-radius: 5px;
  border: none;
  color: transparent;
}

/* Thumb styles */
input[type="range"].styled-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  margin-top: -6px;
  background: #1fff20;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

input[type="range"].styled-slider::-webkit-slider-thumb:hover {
  background: #1fff20;
  transform: scale(1.2);
}

input[type="range"].styled-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  margin-top: -6px;
  background: #1fff20;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

input[type="range"].styled-slider::-moz-range-thumb:hover {
  background: #1fff20;
  transform: scale(1.2);
}

input[type="range"].styled-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  margin-top: -6px;
  background: #1fff20;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s, transform 0.3s;
}

input[type="range"].styled-slider::-ms-thumb:hover {
  background: #1fff20;
  transform: scale(1.2);
}

/* Hide default track in IE */
input[type="range"].styled-slider::-ms-track {
  background: transparent;
  border-color: transparent;
  color: transparent;
}

.rave-mode-box {
  position: absolute;
  inset: 0;
}

.rave-mode-box video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rave-mode-box:before {
  content: "";
  background: #262626cc;
  position: absolute;
  inset: 0;
}

@media screen and (max-width: 1230px) {
  .plinko-bet-btn,
  .plinko-bet-btn:focus,
  .plinko-bet-btn:hover,
  button.btn-gold.plinko-bet-btn.btn.btn-contained.btn-medium:active {
    font-size: 18px !important;
    /* height: 40px !important; */
  }
  .fairness-btn span {
    font-size: 12px;
    line-height: 1.5;
  }
  .no-of-rows .dropdown p,
  .no-of-rows .dropdown-menu .dropdown-item,
  .no-of-rows .dropdown button,
  .no-of-rows .dropdown button:hover,
  .no-of-rows .dropdown button:focus,
  .no-of-rows .show > .btn-success.dropdown-toggle {
    font-size: 14px;
  }
  .no-of-rows .dropdown-toggle::after {
    top: calc(50% - 1px);
  }
}

@media screen and (max-width: 991px) {
  .plinko-bet-btn,
  .plinko-bet-btn:focus,
  .plinko-bet-btn:hover {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 813px) {
  .plinko-bet-btn,
  .plinko-bet-btn:focus,
  .plinko-bet-btn:hover {
    font-size: 14px !important;
  }
  /* .auto-bet.auto-bet-mode {
    margin-bottom: 0;
    margin: -6px 0 6px;
  } */
  .auto-bet.auto-bet-mode p {
    font-size: 14px;
  }
  .toggle-section label {
    width: 40px;
    height: 21px;
  }
  .toggle-section label:after {
    top: 2.5px;
    width: 16px;
    height: 16px;
  }
}

@media screen and (max-width: 768px) {
  .plinko-bet-btn,
  .plinko-bet-btn:focus,
  .plinko-bet-btn:hover {
    font-size: 14px !important;
  }
  .slot-game-footer {
    padding-bottom: 5px;
  }
  .fairness-btn {
    gap: 5px;
  }
}
.resultStatsbar-section {
  width: 35px;
  right: 20px;
  top: 20px;
  height: 504.86px;
  display: block;
  position: absolute;
}

.resultStatsbar-section p {
  margin-bottom: 5px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  border-color: rgb(47 69 83);
  background-color: rgb(47 69 83);
  padding: 6px 2px;
  border-radius: 4px;
  text-align: center;
  width: 100%;
  animation-duration: 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
}

.plinko-game-footer {
  background: #1b1b1b;
  border-top: 1px solid #262626;
  height: 60px;
  padding: 10px 25px;
  width: 100%;
  justify-content: space-between;
  z-index: 9;
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0px 0px 12px 12px;
}
.plinko-game-btns i {
  font-size: 24px;
  color: #a0a0a0;
}
.plinko-game-page .nav-tabs {
  background: #262626;
  border-radius: 30px;
  min-height: 57px;
  border: none !important;
  margin-bottom: 10px !important;
  padding: 5px;
}
.plinko-game-page .nav-tabs li {
  width: 50%;
  min-height: 42px;
}
.plinko-game-page .nav-tabs li button {
  width: 100%;
  border: none;
  border-radius: 30px;
  color: rgb(255 255 255);
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  min-height: 42px;
  padding: 16px 0px;
  text-align: center;
}
.plinko-game-page .nav-tabs .nav-item.show .nav-link,
.plinko-game-page .nav-tabs .nav-link.active {
  background: #333333;
  border: none;
  color: rgb(255 255 255);
}

@media screen and (max-width: 425px) {
  .gameResultpopup p {
    font-size: 30px;
  }
  .gameResultpopup {
    min-width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 480px) {
  .main_section {
    max-width: 100%;
  }
  .peg span {
    font-size: 7px !important;
    font-weight: 900 !important;
  }
}
@media screen and (max-width: 374px) {
  .main_section {
    padding-bottom: 30px;
  }
  .resultStatsbar-section {
    width: 100% !important;
    top: unset;
    left: 20px !important;
    height: auto !important;
    bottom: -25px !important ;
    display: flex;
    gap: 5px;
    position: absolute;
  }
  .resultStatsbar-section p {
    font-size: 9px !important;
    width: 10% !important;
  }
  .calcution-btn {
    font-size: 10px !important;
  }
  .plinko-tab .nav-tabs .nav-link {
    padding: 12px 0px !important;
  }
  .live-stats-heading {
    font-size: 12px;
  }
}

@media screen and (max-width: 319px) {
  .pegs.rows16 .pegs_wrapper {
    transform: scale(0.94);
    transform-origin: center;
  }
}

@media screen and (max-width: 300px) {
  .pegs.rows16 .pegs_wrapper {
    transform: scale(0.9);
    transform-origin: center;
    margin-left: -6px;
  }
}

@media screen and (max-width: 285px) {
  .pegs.rows16 .pegs_wrapper {
    transform: scale(0.88);
    transform-origin: center;
    margin-left: -12px !important;
  }
}
@media screen and (max-width: 315px) {
  .calcution-btn {
    font-size: 8px !important;
  }
}

input:-webkit-autofill {
  color: #fff !important;
  -webkit-text-fill-color: #fff !important;
  box-shadow: 0 0 0px 1000px #262626 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* Autofill styling for Firefox */
input:-moz-autofill {
  background-color: #262626 !important;
  color: #fff !important;
  box-shadow: 0 0 0px 1000px #262626 inset !important;
  transition: background-color 5000s ease-in-out 0s;
}
