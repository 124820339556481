.table > :not(caption) > * > * {
  background-color: transparent !important;
  color: var(--bs-heading-color);
  border: none;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  background-color: #1b1b1b !important;
}

.table > tbody > tr > *:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.table > tbody > tr > *:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.my-bets-title {
  margin-bottom: 20px;
  margin-top: 40px;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  display: flex;
  gap: 5px;
}

.bets-pagination button {
  font-size: 14px;
  font-weight: 800;
  color: #fff;
  border: none;
  background-color: transparent;
}

.bets-pagination button:disabled {
  color: #a0a0a0;
}

.bets-pagination .login-popup.modal {
  position: absolute;
}

.plinko-game-page.login-popup.bets-popup.modal {
  position: absolute;
  margin: 0;
}
