@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");

body {
  background: #141414 !important;
  color: #a0a0a0 !important;
  font-family: "Nunito", sans-serif !important;
}

@media (min-width: 1921px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1400px!important;
  }
}

@media (max-width: 1920px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1400px!important;
  }
}





.App{
  overflow-x: hidden;
}

video#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.wi-test-mode {
  position: absolute;
  top: 4px;
  display: flex;
  left: 5px;
}
.showing_num_records {
  font-size: 12px;
  color: #a7a8ab;
  text-align: right;
}
