.plinko-sidepanel {
  background-color: #1b1b1b;
  padding: 25px 20px;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 0px 14px 0px 0px;
  position: relative;
  right: 0px;
  bottom: 0;
  top: 0;
  transition: all 0.5s;
}
.plinko-sidepanel.expand {
  right: 0;
}
.ball-color-section {
  background-color: #262626;
  width: fit-content;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  border-radius: 12px;
}

.plinko-sidepanel .btn-close{
  position: absolute;
  right: 10px;
  top: 10px;
  background-image: url(../../assets/images/game/iconcross.svg);
  z-index: 999;
  cursor: pointer;
  display: none;
}
.color-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 50px;
  width: calc(33.33% - 10px); /* Three boxes in a row */
  border-radius: 20%;
  overflow: hidden;
  transition: transform 0.3s ease;
  background-color: #1b1b1b;
}

.color-ball {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.color-ball.selected {
  transform: scale(1.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.checkmark {
  color: #000;
  font-size: 1.2em;
  position: absolute;
  font-weight: 800;
}
.range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;
}

.range p {
  font-size: 12px;
  margin: 0;
}

.rave-mode {
  display: flex;
  gap: 10px;
}
.rave-mode .toggle-section {
  justify-content: center;
}

/* Style for the custom slider */
.custom-slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 12px;
  /* Track background color */
  outline: none;
  opacity: 0.8;
  transition: opacity 0.2s;
  border-radius: 6px;
}

.custom-slider:hover {
  opacity: 1;
}

/* Webkit browsers (Chrome, Safari) */
.custom-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  background: #1fff20; /* Thumb color */
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s, transform 0.3s;
}

.custom-slider::-webkit-slider-thumb:hover {
  background: #262626;
  transform: scale(1.2);
}

/* Firefox */
.custom-slider::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #1fff20; /* Thumb color */
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s, transform 0.3s;
}

.custom-slider::-moz-range-thumb:hover {
  background: #262626;
  transform: scale(1.2);
}

.custom-slider::-ms-fill-lower {
  background: #007bff; /* Track background color */
}

.custom-slider::-ms-fill-upper {
  background: #007bff; /* Track background color */
}








@media screen and (max-width: 1320px) {

.plinko-sidepanel {
  z-index: 9999;
  border-left: 1px solid #29424f;
  position: absolute;
  right: -300px;
  border-radius: 14px 0px 0px 0px;
}
.plinko-sidepanel .btn-close {
  display: block;
}

}