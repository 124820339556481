.provably-fair-content h2 {
  color: #fff;
  font-size: 24px;
  font-weight: 800;
}

.security-section {
  background-color: #141414;
  padding: 20px;
  border: 2px solid #333333;
  border-radius: 10px;
}

.general-section {
  background-color: #141414;
  padding: 20px;
  border: 2px solid #333333;
  border-radius: 10px;
}

.input-icon-settings {
  position: absolute !important;
  right: 10px;
  top: 12px;
  cursor: pointer;
}

.mr-divider {
  border-bottom: 2px solid #333333;
}

.security-section input::placeholder {
  color: #fff;
  font-weight: 800;
}

.settings-page input::placeholder {
  color: #a0a0a0;
  font-weight: 600;
}

.settings-page .no-of-rows .dropdown-menu {
  background-color: #141414;
}

.settings-page .no-of-rows .dropdown button {
  color: #a0a0a0;
}

.settings-page .no-of-rows .dropdown p {
  margin-bottom: 10px;
}
