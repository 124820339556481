.bch-game-popup .modal-content {
  background: transparent;
  border: transparent;
}

.bch-game-popup .modal-body {
  padding: 0;
}

.bch-modal-tab {
  display: grid;
  grid-template-columns: 272px 394px max-content;

  align-items: flex-start;
  gap: 30px;
}
.bch-modal-tab .nav-item .nav-link,
.bch-modal-tab .nav-item .nav-link.active,
.bch-modal-tab .nav-pills .nav-link,
.bch-modal-tab .nav-pills .nav-link.active,
.bch-modal-tab .nav-pills .show > .nav-link {
  display: grid;
  grid-template-columns: min-content 1fr;
  gap: 20px;
  align-items: center;
  letter-spacing: 0px;
  border-radius: 6px;
  padding: 15px;
}
.bch-modal-tab .nav-item .nav-link.active span,
.bch-modal-tab .nav-pills .nav-link.active span {
  color: #fff;
}
.bch-modal-tab .nav-pills .nav-link.active svg g,
.bch-modal-tab .nav-pills .nav-link.active svg rect {
  stroke: #fff;
}
.bch-modal-tab .nav-pills .nav-link.active svg path,
.bch-modal-tab .nav-pills .nav-link.active svg rect + path,
.bch-modal-tab .nav-pills .nav-link.active svg rect + rect {
  fill: #fff;
}

.bch-modal-tab .nav-item .nav-link.active,
.bch-modal-tab .nav-pills .nav-link.active {
  background-color: #262626;
}
.bch-modal-tab .nav-pills .nav-link span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: #a0a0a0;
  font-size: 16px;
  line-height: 16px;
  white-space: nowrap;
  text-transform: uppercase;
  font-weight: 700;
}
.bch-modal-tab .nav .nav-link svg rect,
.bch-modal-tab .nav .nav-link svg g {
  stroke: #a0a0a0;
}
.bch-modal-tab .nav .nav-link svg rect + rect,
.bch-modal-tab .nav .nav-link svg rect + path,
.bch-modal-tab .nav .nav-link svg path {
  fill: #a0a0a0;
}
.bch-modal-tab .nav {
  position: relative;
  border-radius: 10px;
  background-color: #141414;
  box-shadow: rgb(0, 0, 0) 0px 0px 20px 0px;
  height: max-content;
  padding: 15px;
}

.bch-modal-tab .nav .nav-link svg {
  width: 26px;
  height: 19px;
}
.bch-tab-pane-content form {
  display: grid;
  gap: 15px;
  max-width: 335px;
  width: 100%;
}

.bch-modal-tab .tab-content {
  position: relative;
  border-radius: 10px;
  background-color: #141414;
  box-shadow: rgb(0, 0, 0) 0px 0px 20px 0px;
  height: max-content;
  padding: 25px 30px;
  width: 100%;
  max-height: 662px;
  overflow: overlay;
}

.bch-tab-pane-content {
  width: 100%;
}

.bch-game-popup .modal-dialog {
  max-width: 776px;
  margin: 80px auto 0px auto;
}
.bch-balance {
  display: grid;
  gap: 15px;
}

.data-box {
  border: 2px solid #333333;
  position: relative;
  min-height: 46px;
  min-width: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  /* padding: 2px; */
}

.value-box {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  background-color: #141414;
  border-radius: 6px;
  padding: 0px 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #a0a0a0;
  gap: 6px;
}
.value-box span {
  line-height: 1;
}
.bch-balance label {
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #a0a0a0 !important;
}
.bch-bitcoin-address {
  display: grid;
  gap: 10px;
}

.bch-bitcoin-address label {
  /* margin: 0;
  text-transform: uppercase;
  color: #fff;
  line-height: 1; */
  margin-bottom: -7px;
}

.bch-bitcoin-address-box {
  display: flex;
  align-items: center;
  border: 2px solid #333333;
  position: relative;
  min-height: 46px;
  min-width: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  /* padding: 2px; */
}
.bch-bitcoin-address-box .address-copy {
  color: #a0a0a0;
}

.address-copy {
  width: max-content;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.address-box {
  margin: 0px;
  padding: 0px 5px 0px 15px;
  box-shadow: none;
  background: transparent;
  border: none;
  border-radius: 0px;
  font-family: inherit;
  font-size: inherit;
  outline: none;
  cursor: inherit;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  flex-grow: 1;
  color: rgb(255, 255, 255);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.copy-button,
.copy-button:hover,
.copy-button:active,
.copy-button:focus {
  background: #333333 !important;
  border-radius: 4px;
  border: none;
  box-shadow: none !important;
  width: 36px;
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
  margin: 0 5px 0 0;
}
.copy-button:hover {
  opacity: 0.85;
}
.bch-qr {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #333333;
  position: relative;
  min-height: 46px;
  min-width: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 20px 0;
}
.bch-tab-pane-content .bch-qr a:hover {
  opacity: 1;
}
.qr-code {
  background: #fff;
  padding: 5px;
  border-radius: 4px;
}
.bch-modal-close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  border-radius: 10px;
  background-color: #262626;
  box-shadow: rgb(0, 0, 0) 0px 0px 20px 0px;
  height: max-content;
  cursor: pointer;
}

.close-icon {
  position: relative;
  width: 18px;
  height: 18px;
}
.close-icon:hover span {
  background: #a0a0a0;
}
.close-icon span:nth-child(1) {
  transform: rotate(45deg);
  top: 8px;
}

.close-icon span {
  position: absolute;
  width: 18px;
  height: 2px;
  background: #a0a0a0;
  border-radius: 3px;
  transition: top 500ms cubic-bezier(0.19, 1, 0.22, 1) 0s,
    bottom 500ms cubic-bezier(0.19, 1, 0.22, 1) 0s,
    transform 500ms cubic-bezier(0.19, 1, 0.22, 1) 83ms;
  will-change: transform;
}

.close-icon span:nth-child(2) {
  transform: rotate(-45deg);
  bottom: 8px;
}
.modal-btn,
.modal-btn:hover,
.modal-btn:active,
.modal-btn:focus {
  position: relative;
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #141414 !important;
  height: 60px;
  padding: 10px 20px !important;
  border-radius: 7px !important;
  border: none !important;
  margin: 0px;
  font-family: inherit;
  font-size: 18px !important;
  font-weight: 800;
  line-height: 22px !important;
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer !important;
  width: 100%;
  box-shadow: none !important;
  transition: all 0.5s;
}
.modal-btn:hover {
  opacity: 0.8;
}
.gradient-btn,
.gradient-btn:hover,
.gradient-btn:active,
.gradient-btn:focus {
  background: linear-gradient(
    225deg,
    #77ffa8 0%,
    #ffe081 33%,
    #ffad8e 68%,
    #a592ff 100%
  );
  color: #212529 !important;
}

.green-btn,
.green-btn:hover,
.green-btn:active,
.green-btn:focus {
  background: #1fff20 !important;
}
.grey-btn,
.grey-btn:hover,
.grey-btn:focus,
.grey-btn:active {
  background: #333333 !important;
  color: #a0a0a0 !important;
}
.deposit-btn .grey-btn,
.deposit-btn .grey-btn:hover {
  font-size: 16px !important;
}

.bch-input-box .form-control,
.bch-input-box .form-control:focus,
.bch-input-box .form-control:active {
  background: #141414;
  border: 2px solid #333333;
  border-radius: 8px;
  height: 46px;
  box-shadow: none !important;
  color: #fff;
  padding: 0 15px;
}

.bch-input-box .form-control::placeholder {
  color: #a0a0a0 !important;
}
.form-control:disabled,
.form-control[readonly] {
  background: #141414 !important;
}
.deposit {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.deposite-img img {
  width: 17px;
  height: 17px;
}

.deposite-img {
  display: flex;
  gap: 10px;
  padding: 0 10px;
}

.bch-amount {
  position: relative;
}

.amount-btn {
  position: absolute;
  display: flex;
  gap: 5px;
  right: 6px;
}
.modal-small-btn {
  height: 32px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 3px !important;
  line-height: 1 !important;
}

.bch-amount {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bch-amount .form-control {
  padding-right: 120px;
}

.amount-bet,
  /* .amount-bet:hover, */
  .amount-bet:active,
  .amount-bet:focus,
  .amount-bet.btn-primary:not(:disabled):not(.disabled):active {
  display: flex !important;
  align-items: center;
  justify-content: center;
  color: #a0a0a0 !important;
  background: #333333 !important;
  height: 32px;
  padding: 0px 12px !important;
  border-radius: 3px !important;
  font-size: 14px !important;
  border: none !important;
  margin: 0px;
  overflow: hidden;
  user-select: none;
  cursor: pointer;
  width: 100%;
  box-shadow: none !important;
}
.amount-bet:hover {
  background-color: #333333 !important;
}
.bch-date-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bch-date-box span {
  color: rgb(92, 92, 92);
}
.value-color-box {
  text-overflow: ellipsis;
  background-image: linear-gradient(
    225deg,
    rgb(119, 255, 168) 0%,
    rgb(255, 224, 129) 33%,
    rgb(255, 173, 142) 68%,
    rgb(165, 146, 255) 100%
  );
  background-size: 100%;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space-collapse: collapse;
  text-wrap: nowrap;
  padding: 0px 15px;
  overflow: hidden;
  background-repeat: repeat;
}

.bch-warning {
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 10px;
  border-radius: 6px;
  padding: 20px;
  line-height: 18px;
  letter-spacing: 0px;
  background: rgb(65, 61, 46);
}
.bch-tab-pane-content .bch-warning p {
  color: rgb(255, 224, 129);
  margin: 0;
  display: grid;
  grid-template-rows: 1fr;
  row-gap: 10px;
  border-radius: 6px;
  padding: 0px;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: start;
  font-size: 16px;
  text-transform: inherit;
}

.bch-modal-tab .bch-tab-vault .tab-content {
  padding: 0;
  background: none;
  box-shadow: none;
}
.bch-tab-vault .nav-tabs {
  border: none;
}

.bch-tab-vault .nav {
  margin: 0 !important;
  width: 100%;
  background: #141414;
  border: 2px solid #141414;
  border-radius: 7px;
  padding: 0;
  box-shadow: none;
}

.bch-tab-vault {
  display: grid;
  gap: 25px;
}

.bch-tab-vault .nav-tabs .nav-item.show .nav-link,
.bch-tab-vault .nav-tabs .nav-link.active {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  text-transform: uppercase;
  color: #fff;
  background: #323232;
  border: none;
  width: 50%;
}

.bch-tab-vault .nav-tabs .nav-link {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  color: #a0a0a0;
  width: 50%;
  margin: 0;
}

.bch-tab-vault .nav-tabs .nav-link:focus,
.bch-tab-vault .nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: unset;
}
/* ****************** deposit btn ************************ */
.bch-deposit {
  position: relative;
  width: 100%;
  text-align: center;
}

.back-btn {
  position: absolute;
  left: 0px;
  cursor: pointer;
}

.back-btn svg {
  width: 14px;
  height: 14px;
  margin-right: 5px;
  transform: rotate(180deg);
}
.bch-tab-pane-content .bch-deposit p {
  margin: 0;
  color: #fff;
  font-size: 16px;
}
/* ************************************ verification ******************************** */
.verification-code-input {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 15px;
}

.verification-code-input input {
  width: 43px;
  height: 46px;
  background: #333;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 8px;
  font-size: 18px; /* Adjust the font size as needed */
  text-align: center;
  outline: none; /* Remove the default input outline */
}

/* Style the cursor when it's focused */
.verification-code-input input:focus {
  border-color: gray; /* Change the color when focused, if desired */
}
.bch-tab-pane-content p.setup-paragraph {
  margin: 0;
  text-align: start;
  color: #fff;
  line-height: 24px;
}
.bch-tab-pane-content p.setup-paragraph a {
  color: #fff;
  text-decoration: underline !important;
}
/* ************************account******************************** */
.bch-bottom-btn-border {
  position: relative;
}
.bch-bottom-btn-border::after {
  content: " ";
  position: absolute;
  height: 2px;
  background-color: rgb(7, 7, 7);
  left: -30px;
  right: -30px;
}
.bch-tab-pane-content a,
.bch-tab-pane-content a:hover {
  color: #a0a0a0;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.5s;
}
.bch-tab-pane-content a:hover {
  opacity: 0.6;
}
/* ************************bonus************************************* */

.bch-tab-pane-content h4 {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.bch-tab-pane-content p {
  line-height: 26px;
  font-size: 16px;
  letter-spacing: 0px;
  color: #a0a0a0;
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
  margin: 15px 0px;
}
.bch-tab-pane-content span.btn-or {
  line-height: 0;
  font-size: 16px;
  letter-spacing: 0px;
  color: #a0a0a0;
  margin: 0px auto;
  text-transform: uppercase;
  padding: 2px 0;
}
.bch-wallet {
  margin: 35px auto;
  height: 120px;
}
.bch-progressbar-box {
  display: grid;
  gap: 15px;
  background-color: rgb(40, 40, 40);
  border-radius: 5px;
  padding: 15px;
}

.bch-progress-point span {
  color: rgb(212, 212, 212);
  background: rgb(70, 70, 70);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 14px;
}

.bch-progress-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bch-progress-xp {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-bar-content .progress {
  position: relative;
  background: rgb(20, 20, 20);
  width: 100%;
  height: 7px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-content .progress-bar {
  background: #fff;
}

.bch-progressbar-box {
  display: grid;
  gap: 15px;
  background-color: rgb(40, 40, 40);
  border-radius: 5px;
  padding: 15px;
}

.bch-progress-point span {
  color: rgb(212, 212, 212);
  background: rgb(70, 70, 70);
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 14px;
}

.bch-progress-point {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bch-progress-xp {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  color: #a0a0a0;
  line-height: 1;
}

.progress-bar-content .progress {
  position: relative;
  background: rgb(20, 20, 20);
  width: 100%;
  height: 7px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar-content .progress-bar {
  background: #fff;
}

.bch-progressbar-content span.progress-level {
  display: flex;
  padding: 25px 0 20px;
}

.level-box {
  color: rgb(212, 212, 212);
  background: rgb(70, 70, 70);
  margin: 0 5px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 14px;
}
.btn.disabled,
.btn:disabled,
.btn-primary.disabled,
.btn-primary:disabled,
.gradient-btn:disabled {
  cursor: not-allowed !important;
}
.nav-link.disabled {
  pointer-events: inherit;
}
/* ************************** affilitated ************************ */
h2.bch-heading {
  color: rgb(255, 255, 255);
  line-height: 1;
  font-size: 16px;
  letter-spacing: 0px;
  word-break: break-word;
  margin: 0;
}
.fairness-modal form {
  gap: 20px;
}
p.bch-heading {
  color: #a0a0a0;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: 0px;
  word-break: break-word;
  text-align: left;
  margin: 0;
  text-transform: none;
}
.bch-tab-pane-content ul {
  list-style-type: disc !important;
  /* padding-left: 1rem !important; */
}
.bch-tab-pane-content ul li {
  color: #a0a0a0;
  line-height: 22px;
  font-size: 16px;
  letter-spacing: 0px;
  word-break: break-word;
  list-style-position: inside;
}
.circle {
  border-radius: 100%;
  overflow: hidden;
  height: 10px;
  width: 10px;
  /* background: rgb(100, 100, 100); */
}
.bch-progress-green span {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 14px;
  background: rgb(55, 102, 72);
  color: rgb(119, 255, 168);
}

.bch-progress-green {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
p.progress-level {
  display: flex;
  justify-content: flex-start;
  color: #fff;
  line-height: 2;
}
.affiliate-modal p.progress-level {
  margin: 0;
}
p.progress-level span {
  margin: 0 5px;
}

.bch-share-box {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
}

.bch-share-box .share-box {
  display: flex;
  align-items: center;
  justify-content: center;

  aspect-ratio: 1 / 1;
  background: #444;
  border-radius: 7px;
}
.bch-share-box .share-box:hover {
  cursor: pointer;
  opacity: 0.8;
}
.game-dropdown-content .dropdown button,
.game-dropdown-content
  .dropdown
  button.btn-primary:not(:disabled):not(.disabled):active,
.game-dropdown-content .dropdown.show > .btn-primary.dropdown-toggle,
.game-dropdown-content
  .dropdown
  button
  .show
  > .btn-primary.dropdown-toggle:focus {
  -webkit-box-align: center;
  align-items: center;
  height: 44px;
  border: 2px solid #333333 !important;
  padding: 0px 12px;
  color: #a0a0a0;
  border-radius: 8px;
  /* background: rgb(41 41 41); */
  background: transparent;
  overflow: hidden;
  display: grid;
  grid-template-columns: 1fr max-content;
  gap: 10px;
  width: 100%;
  min-height: 46px;
  box-shadow: none;
}

.game-d-box {
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 15px;
}
.game-d-box svg {
  width: 22px;
  height: 22px;
}
.game-d-box span {
  text-align: left;
  text-transform: capitalize;
}

.game-dropdown-content .dropdown button.show > .btn-primary.dropdown-toggle {
  border: 2px solid #333333 !important;
  background: #141414;
}

.game-dropdown-content .show.dropdown .dropdown-menu {
  width: 100%;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  border: 2px solid #a0a0a0;
  border-top: none;
  background: rgb(41, 41, 41);
  margin-top: -2px !important;
  padding: 0;
}
.game-dropdown-content .dropdown.show > .btn-primary.dropdown-toggle,
.game-dropdown-content
  .dropdown
  button.btn-primary:not(:disabled):not(.disabled):active,
.game-dropdown-content .dropdown.show > .btn-primary.dropdown-toggle {
  border: 2px solid #a0a0a0 !important;
  border-radius: 8px 8px 0 0;
  border-bottom: none !important;
  box-shadow: none !important;
  background: rgb(41 41 41);
}

.game-dropdown-content .dropdown-item {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0px 12px;
  cursor: pointer;
  color: #a0a0a0;
  border-color: transparent;
}

.game-dropdown-content .dropdown-item:focus,
.game-dropdown-content .dropdown-item:hover {
  background-color: #141414;
  color: #fff;
  opacity: 1;
}
.game-dropdown-content .dropdown-item:hover svg g g,
.game-dropdown-content .dropdown-item:hover svg g path {
  fill: #fff;
}
.game-dropdown-content .dropdown-toggle::after {
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
  border-right-style: solid !important;
  border-right-width: 2px !important;
  content: "";
  display: inline-block;
  height: 10px;
  width: 10px;
  transform: rotate(45deg);
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  position: absolute;
  right: 20px;
  top: 16px;
}
.game-dropdown-content .dropdown.show > .btn-primary.dropdown-toggle::after {
  transform: rotate(225deg);
  top: 20px;
}
.code-content-box {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  height: 100%;
}

.copy-code {
  position: absolute;
  right: 10px;
  bottom: 10px;
}
.code-header {
  width: 100%;
  padding: 10px;
  background: rgb(50, 50, 50);
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.roulette-content {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgb(20, 20, 20) !important;
}

.roulette-code-block {
  max-height: 300px;
  position: relative;
  overflow: overlay;
}

.bch-modal-tab .tab-content::-webkit-scrollbar-track,
.roulette-code-block::-webkit-scrollbar-track,
.bch-help-content::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 4px;
}

.bch-modal-tab .tab-content::-webkit-scrollbar,
.roulette-code-block::-webkit-scrollbar,
.bch-help-content::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
  background-color: transparent;
}

.bch-modal-tab .tab-content::-webkit-scrollbar-thumb,
.roulette-code-block::-webkit-scrollbar-thumb,
.bch-help-content::-webkit-scrollbar-thumb {
  background-color: transparent;

  border-radius: 4px;
}
.bch-modal-tab .tab-content:hover::-webkit-scrollbar-thumb {
  background-color: rgba(68, 68, 68, 0.5);
}

/* .bch-modal-tab .tab-content::-webkit-scrollbar-track,
  .roulette-code-block::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
    height: 51px;
  }
  .bch-modal-tab .tab-content::-webkit-scrollbar,
  .roulette-code-block::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
    border-radius: 4px;
  }
  .bch-modal-tab .tab-content::-webkit-scrollbar-thumb,
  .bch-modal-tab .tab-content::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 51px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
    background-color: transparent;
    transition: all 0.5s;
  }
  .bch-modal-tab .tab-content:hover::-webkit-scrollbar-thumb,
  .roulette-code-block:hover::-webkit-scrollbar-thumb {
    border-radius: 4px;
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
    background-color: transparent;
  }
  .bch-modal-tab .tab-content::-webkit-scrollbar-thumb:hover,
  .roulette-code-block::-webkit-scrollbar-thumb:hover {
    background-color: #8289a3;
  } */
/* *************************** help ********************************** */
.bch-modal {
  display: flex;
  gap: 30px;
  height: 100%;
}

.bch-help-content {
  max-width: 394px;
  width: 100%;
  padding: 30px;
  background: #202020;
  border-radius: 10px;
  box-shadow: rgb(0, 0, 0) 0px 0px 20px 0px;
  max-height: 650px;
  overflow: overlay;
  display: grid;
  gap: 25px;
}
/* Define the arrow style for the Accordion header */
.accordion-header-arrow::before {
  border-bottom-style: solid !important;
  border-bottom-width: 2px !important;
  border-right-style: solid !important;
  border-right-width: 2px !important;
  content: "";
  display: inline-block;
  height: 13px;
  width: 13px;
  transform: rotate(45deg);
  border-top: none;
  border-right: none;
  border-bottom: none;
  border-left: none;
  position: absolute;
  right: 22px;
  top: 14px;
  border-color: #a0a0a0;
}

.game-modal .modal-dialog {
  max-width: 474px;
}

.bch-help-content .card {
  background: rgb(50, 50, 50);
  cursor: pointer;
}

.bch-help-content .accordion {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}

.bch-help-content .accordion > .card:not(:last-of-type) {
  border-radius: 7px;
}

.bch-help-content .accordion > .card > .card-header {
  border-radius: 7px;
  padding: 13px 20px;
}

.bch-help-content .accordion > .card:not(:first-of-type) {
  border-radius: 7px;
}
.bch-help-content .card-header {
  border-bottom: none;
  background: transparent;
  font-size: 16px;
  letter-spacing: 0px;
  color: rgb(255, 255, 255);
  cursor: pointer;
  user-select: none;
  text-transform: uppercase;
}
.bch-help-content .card-body {
  padding: 13px 20px;
  display: grid;
  gap: 16px;
}
.bch-help-content .card-body p {
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #a0a0a0;
  margin: 0;
}
.bch-help-content .card-body ul {
  list-style-type: disc !important;
  padding-left: 14px !important;
  color: #a0a0a0 !important;
}
.bch-help-content .card-body p a {
  text-decoration: underline !important;
  color: #a0a0a0 !important;
}
/* .bch-help-content .accordion .collapse {
    background: url(../../assets/images/modal/arrow.svg);
    position: absolute;
    top: 0;
    right: 0;
    width: 20px;
    height: 20px;
  } */
.bch-help-content a {
  color: #a0a0a0 !important;
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 16px;
  text-align: center;
  text-decoration: none !important;
  transition: all 0.5s;
}
.bch-help-content a:hover {
  opacity: 0.6;
}
/* *********************************************** */
.game-text {
  color: rgb(92, 92, 92);
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 24px;
  text-transform: uppercase;
}
.game-text span {
  color: #fff;
}

.box-tower-container {
  display: grid;
  grid-template-columns: repeat(4, 62px);
  grid-gap: 10px;
  background: rgb(20, 20, 20);
  padding: 30px;
  border-radius: 7px;
}

.box-tower {
  width: 62px;
  height: 62px;
  opacity: 0.5;
  cursor: default;
  border-width: 2px;
  border-color: rgb(51, 51, 51);
  background-color: rgb(51, 51, 51);
  background-size: 400% 100%;
  border-radius: 7px;
}
.mine-box-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 5px;
  height: 100%;
  width: 100%;
}

.diamond-box {
  cursor: default;
  border-width: 2px;
  border-color: rgb(44, 63, 52);
  background-color: rgb(44, 63, 52);
  background-size: 500% 500%;
  background-position: 0% 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.diamond-box svg,
.bomb-box svg {
  min-height: 35px;
  width: 35px;
}

.bomb-box {
  cursor: default;
  border-width: 2px;
  border-color: rgb(51, 51, 51);
  background-color: rgb(51, 51, 51);
  background-size: 500% 500%;
  background-position: 0% 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* ********************** roulette **************************************** */

.bet-list ul {
  /* height: 340px; */
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  /* gap: 15px; */
  width: 100%;
  padding: 8px 30px 30px 30px;
}

.bet-list ul::-webkit-scrollbar-track {
  background-color: #16181e;
}

.bet-list ul::-webkit-scrollbar {
  width: 3px;
  background-color: #16181e;
}

.bet-list ul::-webkit-scrollbar-thumb {
  background-color: #1f2127;
}
.bet-list ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 7px;
  font-size: 15px;
  color: #a0a0a0;
  height: fit-content;
  /* border-top: 1px solid #292929; */
  width: 100%;
}
.bet-list ul li:nth-child(odd) {
  background: #282828;
}
.bet-user {
  display: flex;
  align-items: center;
  gap: 8px;
}
.selected-box {
  height: 10px;
  width: 10px;
  background: #3abb37;
  border-radius: 50px;
}
.red-box {
  background: #ff6060;
}
.red-box {
  background: rgb(255, 96, 96);
}
.green-box {
  background: rgb(119, 255, 168);
}
.black-box {
  background: rgb(100, 100, 100);
}

span.yellow-badge {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 14px;
  color: rgb(119, 255, 168);
  background: rgb(56, 83, 66);
}
span.blue-badge {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 14px;
  color: rgb(163, 255, 251);
  background: rgb(59, 83, 82);
}
span.pink-badge {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 18px;
  line-height: 18px;
  text-align: center;
  border-radius: 4px;
  padding: 1px 5px;
  font-size: 14px;
  color: rgb(255, 168, 168);
  background: rgb(83, 63, 63);
}
.bet-user h5 {
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  color: #a0a0a0;
  margin: 0;
}
.bet-user-amount {
  color: #a0a0a0;
  display: flex;
  align-items: center;
}
/* ************************** error msg ************************************ */

p.error-msg {
  color: rgb(255, 173, 142);
  font-size: 16px;
  letter-spacing: 0px;
  word-break: break-word;
  margin: 0;
  text-align: start;
  line-height: 1;
  text-transform: none;
}
.error-field .form-control {
  border: 2px solid rgb(255, 173, 142);
}
/* ************************************************************** */

/******************** Deposit Dropdown ***********************/

.deposit-select-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deposit-select-list-info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.deposit-select-search {
  display: flex;
  align-items: center;
}

.deposit-select-search input {
  width: 100%;
  background: #141414;
  border: 2px solid #333333;
  border-radius: 8px;
  height: 46px;
  box-shadow: none !important;
  padding: 0 15px;
  padding-left: 40px;
  color: #a0a0a0;
}

.deposit-select-search input::placeholder {
  color: #a0a0a0;
}

.deposit-select-search svg {
  position: absolute;
}
.customSelect {
  background: transparent;
  color: #a0a0a0;
  height: 42px;
  border-radius: 6px;
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  outline: none;
  background: #141414;
}

.visible-content button {
  background: transparent;
  border: none;
}

/* loader css */

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

/* loader css end */

@media screen and (max-width: 767.98px) {
  .bch-modal-tab {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    position: relative;
  }
  .bch-modal-tab > *:nth-child(3) {
    position: absolute;
    right: 0;
  }
  .bch-tab-pane-content form {
    max-width: 100%;
  }
  .bch-modal-tab .nav {
    flex-wrap: unset;
    display: flex;
    flex-direction: row !important;
    width: calc(100% - 70px);
    padding: 10px;
    gap: 0;
    overflow-x: scroll;
  }

  .bch-modal-tab .nav-item .nav-link,
  .bch-modal-tab .nav-item .nav-link.active,
  .bch-modal-tab .nav-pills .nav-link,
  .bch-modal-tab .nav-pills .nav-link.active,
  .bch-modal-tab .nav-pills .show > .nav-link {
    padding: 7px 10px;
    flex-basis: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-basis: 100%;
    width: 100%;
  }

  .bch-modal-tab .nav .nav-link svg {
    display: none;
  }

  .bch-modal-tab .nav .nav-item {
    width: 100%;
  }
  .bch-tab-vault .nav {
    width: 100% !important;
    padding: 5px !important;
  }
  .bch-tab-vault .nav-tabs .nav-item.show .nav-link,
  .bch-tab-vault .nav-tabs .nav-link.active {
    width: 100%;
  }
  .bch-progressbar-content span.progress-level {
    padding-top: 15px;
    padding-bottom: 0;
  }
}
@media screen and (max-width: 420px) {
  .bch-modal-tab {
    margin: 0;
  }
}
