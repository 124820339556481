.login-form {
  max-width: 450px;
  margin: 0px auto;
  padding: 0px 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-form img {
  max-width: 250px;
  margin: 0px auto;
  display: block;
}
.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid#018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.login-form h1 {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  margin: 0;
}

.login-form p {
  text-align: center;
  color: #a0a0a0;
  font-size: 15px;
}

.login-form h1 span {
  color: #fdf401;
}

.login-box {
  padding-top: 5px;
  max-width: 400px;
}

.login-box form {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.login-box .form-group .form-control {
  height: auto;
  padding: 12px 30px 12px 15px;

  border-radius: 4px;
  font-size: 15px;
  color: #a0a0a0;
}

.login-box .form-group .form-control::placeholder {
  color: #a0a0a0;
}

/* .login-box .form-group .form-control:focus {
  box-shadow: none;
  background: #000616;
} */

.login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.login-box .form-group {
  margin-bottom: 25px;
  position: relative;
}

.login-button {
  text-align: center;
  margin-top: 10px;
}

.login-box p {
  text-align: center;
  padding-top: 20px;
  font-size: 14px;
  color: #a0a0a0;
  max-width: 250px;
  margin: 0px auto;
}

.signup-link:hover {
  color: #c5c5c5;
}

.forget-link {
  color: #1fff20;
  font-size: 14px;
}

.forget-link:hover {
  color: #a0a0a0;
  text-decoration: none;
}

.forget-password {
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.remember-me .form-check-label {
  font-weight: 400;
  font-size: 14px;
  color: #a0a0a0;
}

.social-login {
  text-align: center;
  display: none;
}

.social-login span {
  color: #afb4d0;
  display: block;
  padding: 10px 0px;
  font-size: 15px;
}

.social-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social-btns .btn {
  width: 100%;
  font-size: 15px;
  padding: 11px 20px;
}

.facebook-btn {
  background: #3b5998 !important;
  border-color: #3b5998 !important;
}

.twitch-btn {
  background: #242936 !important;
  border-color: #242936 !important;
}

.login {
  position: relative;
}

.login-popup .modal-header {
  border-bottom: none;
  padding: 10px 0px;
  padding-bottom: 0;
}

.login-popup .form-label {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: 700;
}

.login-popup .modal-header .close:focus {
  outline: none;
}

.login-popup .close {
  color: #a0a0a0;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
  cursor: pointer;
}

.login-popup .close:hover {
  color: #efebeb;
}

.modal-backdrop {
  background-color: #3b3b3b !important;
  opacity: 0.9 !important;
}

.login-popup .modal-body {
  padding: 0;
}

.login-popup .modal-content {
  position: relative;
  background: #1b1b1b;
  width: fit-content;
  padding: 8px 20px;
}

.login-popup .modal-dialog-centered {
  justify-content: center;
}

/* .login-popup .modal-header .btn-close {
  display: contents;
} */

.forget-link,
.forget-link:focus,
.forget-link:hover {
  background: transparent !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #1fff20 !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.forget-link.btn-primary:not(:disabled):not(.disabled).active:focus,
.forget-link.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important;
}
p.error-msg {
  text-align: left !important;
  color: #ba1212 !important;
  font-weight: 600;
  padding-top: 10px;
  margin-bottom: 0px !important;
}

.error-msg .input-icon1 {
  position: absolute !important;
  right: 255px !important;
  top: 185px !important;
  cursor: pointer;
}




/* [type="checkbox"] + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  height: 15px;
  width: 15px;
  outline: 2px solid #201e2e;
  border-radius: 2px;
  background-color: #000616;
} */

/* [type="checkbox"]:checked + label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  height: 15px;
  width: 15px;
  outline: 2px solid #0e1121;
  border-radius: 2px;
  background-color: #000616;
} */

/* [type="checkbox"]:checked + label::after {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  display: inline-block;
  width: 6px;
  height: 12px;
  border: 1px solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  border-radius: 0px;
  margin: 0px 15px 5px 5px;
} */

.input-icon1 {
  position: absolute !important;
  right: 10px;
  top: 36px;
  cursor: pointer;
}
@media screen and (max-width: 375px) {
  .forget-link {
    font-size: 9px !important;
  }
  .header .header-logo-menu {
    width: unset !important;
  }
  p.error-msg {
    font-size: 12px;
  }
}
@media screen and (max-width: 280px) {
  .forget-link {
    font-size: 9px !important;
  }
  .header .header-logo-menu {
    width: 10px !important;
  }
}
