.my-bets-popup-content {
  overflow-y: scroll;
  max-height: 800px;

  padding: 0 20px;
  overflow-x: hidden;
}

.my-bets-popup-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.my-bets-popup-header h2 {
  color: #fff;
  font-size: 1rem;
}

.my-bets-popup-header h3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 800;
  margin-bottom: 20px;
}

.my-bets-popup-header p {
  color: var(--bs-heading-color);
  font-size: 1rem;
  margin-bottom: 2px;
}

.body-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  background-color: #262626;
  padding: 16px;
}

.body-content > :nth-child(1) {
  border-right: 1px solid #557086;
}

.body-content > :nth-child(2) {
  border-right: 1px solid #557086;
}

.body-content-div {
  display: flex;
  flex-direction: column;
  text-align: center;

  min-width: 146px;
  width: 100%;
}

.body-content-div h2 {
  color: var(--bs-heading-color);
  font-size: 14px;
}

.body-content-div p {
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 0px;
}

.multiplier-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.multiplier {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ff7818;
  color: #fff;
  font-weight: 800;
  font-size: 14px;
  border-radius: 5px;
  height: 40px;
  width: 48px;
  border-bottom: 2px solid #a93000;
}

.multiplier p {
  margin-bottom: 0px;
}

.bets-popup-btn {
  color: #fff;
  background-color: #333333;
  padding: 16px;
  border-radius: 5px;
  border: none;
  font-weight: 600;
}

.bets-popup-form {
  padding-bottom: 20px;
}

.my-bets-popup-footer {
  background-color: #262626;
  padding: 17px 20px 10px 20px;
  margin: 23px -21px -9px -21px;
}

.my-bets-popup-footer .my-bets-popup-footer-content form {
  display: flex;
  flex-direction: column;
  gap: 12px !important;
}

.my-bets-popup-footer-button h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
}
.my-bets-popup-footer-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.my-bets-popup-footer-button svg {
  margin-top: -13px;
}

.my-bets-popup-footer-content h3 {
  /* color: #fff; */
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}

.my-bets-popup-footer-content p {
  /* color: #fff; */
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 8px;
  margin-top: 20px;
  cursor: pointer;
}

.my-bets-popup-footer-content h3:hover {
  color: #fff;
} 


.my-bets-popup-footer-content p:hover {
  color: #fff;
} 







.bets-popup .modal-content {
  position: relative;
  background: #1b1b1b;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 0px !important;
}

.bets-popup .modal-header {
  border-bottom: none;
  padding: 10px 20px;
  padding-bottom: 0;
}

.bets-popup .modal-title {
  font-weight: 800;
  font-size: 20px;
}

.my-bets-popup-content::-webkit-scrollbar {
  width: 5px !important;
}

.my-bets-popup-content::-webkit-scrollbar-track {
  background: #262626;
}

.my-bets-popup-content::-webkit-scrollbar-thumb {
  background: #333333;
}

.my-bets-popup-content::-webkit-scrollbar-thumb:hover {
  background: #333333;
}
