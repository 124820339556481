.affiliate-card {
  background-color: #141414;
  padding: 16px;

  border: 2px solid #333333;
  border-radius: 10px;
}

.affiliate-card h2 {
  font-size: 16px;
}

.affiliate-card p {
  margin-bottom: 0;
}

.affiliate-details img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
