.game-info-header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  margin-top: 16px;
}

.game-info-header-box {
  background-color: #333333;
  border-radius: 60px;
  border: 5px solid #262626;
  padding: 15px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 84px;
  cursor: pointer;
}

.game-info-header-box h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.game-info-popup .modal-title {
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}

.game-info-popup {
  position: absolute;
  margin: 0;
}

.game-info-content p {
  margin-bottom: 4px;
  max-width: 500px;
}

.game-info-content {
  margin-top: 15px;
  margin-bottom: 15px;
}
