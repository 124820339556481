.tableWrap{
    width: 100%;
  overflow-x: auto;
}
.tableWrap .table {
    width: 100%;
    border-collapse: collapse;
  }
  
 .tableWrap .tr {
    height: 25px;
  }
  
.tableWrap .td {
    padding: 6px;
  }
  
 .tableWrap .cell-content {
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    color: #fff;
  }
  .tableHeading{
margin-bottom: 8px;
font-size: 16px;
font-weight: 600;
color: #d5d5d5;
  }
  

  .payout-index {
    font-weight: 600; 
    line-height: 1.2; 
    text-align: left;
    color: #fff;
    display: inline-block;
    padding: 2px 4px;
    font-size: 14px;
  }



  
.tableSecond  table {
    width: 100%;
    border-collapse: collapse;
  }
  
 .tableSecond .td {
    padding: 6px;
  }
  
.tableSecond  .cell-content {
    font-family: monospace;
    white-space: nowrap;
  }
  
.tableSecond  .cell-content.subtle {
    color: #a0a0a0;
  }
  
 .tableSecond .weight-normal {
    font-weight: normal;
  }
  
 .tableSecond .weight-semibold {
    font-weight: 600; 
  }
  
 .tableSecond .line-height-default {
    line-height: 1.2; 
  }
  
 .tableSecond .align-left {
    text-align: left;
  }
  
 .tableSecond .size-default {
    font-size: 1rem;
  }
  
 .tableSecond .text-size-default {
    font-size: 1rem; 
  }
  
 .tableSecond .variant-highlighted {
    color: #fff;
  }
  
 .tableSecond .variant-subtle {
    color: #a0a0a0;
  }
  
 .tableSecond .with-icon-space {
    margin-right: 0.5rem; 
  }
  