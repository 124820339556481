.register-form {
  max-width: 450px;
  margin: 0px auto;
  padding: 0px 0px;
  position: relative;
}

.register-form img {
  max-width: 250px;
  margin: 0px auto;
  display: block;
}

.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid #ff5917;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.register-form h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  max-width: 400px;
  margin: 0px auto;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.register-form p {
  text-align: center;
  color: #a0a0a0;
  font-size: 15px;
  max-width: 270px;
  margin: 0px auto;
  display: flex;
  gap: 5px;
  justify-content: center;
}

.register-form h1 span {
  color: #fdf401;
}

.register-form .register-tab {
  margin-top: 20px;
  margin-bottom: 0px;
}

.register-form .register-tab form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.terms-link {
  color: #a0a0a0;
  text-decoration: underline;
}

.terms-link:hover {
  color: #fdf401;
}

.dis-show .form-check {
  display: inline-block;
}

.dis-show p {
  font-size: 14px;
  color: #a0a0a0;
  margin: 0;
  text-align: left;
}

.dis-show {
  display: flex;
  align-items: flex-start;
}

.s-btn.btn.btn-primary {
  padding: 12px 40px;
  font-size: 16px;
  border-radius: 3px;
  font-weight: 600;
  color: #1b1f22;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 15px;
  background: #fdf401 !important;
  box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 15px inset;
}

.s-btn.btn.btn-primary:hover,
.s-btn.btn.btn-primary:focus,
.s-btn.btn.btn-primary:active,
.s-btn.btn.btn-primary:active:focus {
  border: none;
  background: #fdf401;
  color: #1b1f22;
  box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 15px inset !important;
}

.react-tel-input input.form-control {
  width: 100%;
  height: 46.8px !important;
  padding-left: 48px !important;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #242936 !important;
  /* border: 1px solid #242936 !important; */
  border-radius: 3px 0 0 3px;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #323745 !important;
}

.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
  background-color: #242936 !important;
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}

.react-tel-input .country-list .country:hover {
  background-color: #2a2e3a !important;
}

.react-tel-input .country-list .country.highlight {
  background-color: #2a2e3a !important;
}

.react-tel-input .country-list .country {
  padding: 7px 9px;
  border-bottom: 1px solid #343a40 !important;
}

.register-form .form-group span {
  position: unset !important;
  right: 10px;
  top: 40px;
  cursor: pointer;
}

.react-tel-input .country-list .country .dial-code {
  color: #939393 !important;
}

ul.country-list::-webkit-scrollbar {
  width: 6px !important;
}

ul.country-list::-webkit-scrollbar-thumb {
  background: #0d1322;
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background: #2a2e3a !important;
  border-radius: 3px 0 0 0;
}

.register-form .form-group .form-control {
  height: auto;
  padding: 12px 15px;
  background: #000616;
  border: 1px solid #201e2e;
  border-radius: 4px;
  font-size: 15px;
  color: #a0a0a0;
}

.register-form .form-group .form-control::placeholder {
  color: #a0a0a0;
}

.register-form .form-group .form-control:focus {
  box-shadow: none;
  background: #000616;
}

.register-form input::-internal-autofill-selected {
  background: transparent !important;
}

.register-form .form-group .input-icon1 {
  position: absolute !important;
  right: 10px;
  top: 40px;
  cursor: pointer;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border: 2px solid #201e2e;
  /* -webkit-text-fill-color: #ffffff; */
  -webkit-box-shadow: 0 0 0px 1000px #000616 inset;
  transition: background-color 5000s ease-in-out 0s;
  outline: none;
}

.signup .form-label {
  font-size: 14px;
  color: #a0a0a0;
  font-weight: 700;

}

.already {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 14px !important;
  color: #a0a0a0;
}

.signup {
  position: relative;
}

input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

input[type="date"]:before {
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 15px;
  color: #a0a0a0;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.signup-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.signup-popup .modal-header .close:focus {
  outline: none;
}

.signup-popup .close {
  color: #a0a0a0;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
  cursor: pointer;
}

.signup-popup .close:hover {
  color: #efebeb;
}

.already {
  text-align: center;
  padding-top: 30px;
  font-size: 14px;
  color: #a0a0a0;
}

.signup-popup .modal-content {
  position: relative;
  background-size: cover;
  background-position: center;
  position: relative;
  background: #0d1322;
}

.signup-popup .modal-body {
  padding-top: 0;
}

.half-width {
  display: flex;
  gap: 10px;
}

.register-form .form-group img {
  max-width: 24px;
  margin: 0;
}

.register-form .form-group span {
  position: absolute;
  right: 10px;
  top: 40px;
  cursor: pointer;
}

.register-form .form-group {
  position: relative;
}

p.error-text {
  text-align: left;
  max-width: 100%;
  font-size: 12px;
  color: #d74e44;
}

.one-row {
  display: flex;
  gap: 12px;
 justify-content: space-between;
  width: 100%;
  align-items: center;
}

.half-width-box {
  width: calc(50% - 6px);
}

.one-row .form-group {
  width: 50%;
}

.email-notification {
  max-width: 500px;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  gap: 20px;
}

.email-notification img {
  width: 200px;
}

.email-notification h1 {
  font-size: 40px;
  font-weight: 500;
  line-height: 140%;
  color: #ffffff;
}

.email-notification h1 span {
  color: #fdf401;
}

.email-notification span.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  border-radius: 3px;
  font-weight: 600;
  font-size: 18px;
  line-height: 14px;
  color: #1b1f22;
  background: #fdf401;
  box-shadow: rgba(255, 176, 25, 0.4) 0px 0px 10px,
    rgba(255, 255, 255, 0.2) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -3px 0px inset, rgb(255, 135, 25) 0px 0px 15px inset;
}

label.error-msg.form-label {
  color: #ba1212;
  font-weight: 600;
  padding-top: 10px;
}

.register-btn.btn:disabled {
  opacity: 1;
  color: #1a1818 !important;
}

.selectOption {
  width: 100%;
  border-width: 2px;
  border-color: #333333;
  border-style: solid;
  border-radius: 4px;

  background: #262626;
  color: #cbcbcb;
  padding: 8px 15px;
  outline: none;
  font-size: 14px;
  font-weight: 700;
  height: 41px;
}

.selectOption:focus {
  border-color: #333333;

  background: #262626;
  color: #cbcbcb;
}

#formBasicSelect {
  width: 100%;
  border-width: 2px;
  border-color: #333333;
  border-style: solid;
  border-radius: 4px;
  background-color: #262626;
  color: #ffffff;
  padding: 8px 15px;
  outline: none;
  font-size: 14px;
  font-weight: 700;
}

.react-tel-input input.form-control {
  width: 100%;
  height: 41px !important;
  padding-left: 7px !important;
  width: 100%;
  border-width: 2px;
  border-color: #333333;
  border-style: solid;
  border-radius: 4px;
  background-color: #262626;
  color: #ffffff;
  padding: 8px 15px;
  outline: none;
  font-size: 14px;
  font-weight: 700;
}

.formBasicFirst {
  padding-left: 8px;
}

.special-label {
  display: none;
}
