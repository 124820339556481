.provably-fair-page .container {
  margin-top: 30px;
}

.custom-tab-container {
  transition: all 0.3s ease;
  border-radius: 8px;
}

.custom-tab-nav .nav-link {
  color: #ffffff;
  background-color: #262626;
  border: none;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  border-left: 3px solid transparent;
  border-radius: 0px !important;
}

.provably-fair-title {
  font-size: 18px;
  font-weight: 700;
  color: #fff;

  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-tab-nav .nav-link.active {
  background-color: #1b1b1b;
  border-left: 3px solid #1fff20 ;
  .overview h2 {
    font-size: 24px;
    font-weight: 800px;
    color: #fff;
  }
}

.custom-tab-nav .nav-link:hover {
  background-color: #262626;
}

.provably-fair-content {
  background-color: #262626;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}

.code-container {
  background-color: #1b1b1b;
  border-radius: 8px;
  color: #fff;
  padding: 18px;
  margin-bottom: 20px;
}

.code-container h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 1.5;
}
/* OVERVIEW  */

.overview h2 {
  font-size: 24px;
  font-weight: 800;
  color: #fff;
}

.overview a {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
}

/* calculations  */

.calculations-box {
  border: 1px dashed #333333;
  border-radius: 8px;
  padding: 50px 0px;
}

.calculations-content {
  max-width: 600px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.calculations-content form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.multiplier-provably-fair {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-weight: 800;
  font-size: 14px;
  border-radius: 5px;
  height: 40px;
  width: 48px;
}

.multiplier-provably-fair p {
  margin-bottom: 0px;
}

@media screen and (max-width: 500px) {
  .code-container {
    -ms-overflow-style: none;
    overflow: scroll;
    scrollbar-width: none;
  }

  .code-container h3 {
    width: 442px !important;
  }
  .calculations {
    padding: 20px 0px;
  }
}

td.td.marked span {
  background: #0071f1;
  padding: 8px;
}
