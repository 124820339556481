.plinko-game-tables .nav-tabs {
  background: #262626;
  border-radius: 30px;
  min-height: 57px;
  border: none !important;
  margin-bottom: 10px !important;
  padding: 5px;
  width: 37%;
}

.plinko-game-tables .nav-tabs li {
  width: 33.33%;
  min-height: 42px;
}

.plinko-game-table-filter {
  margin-bottom: -75px;
  justify-content: end;
}

.plinko-game-table-filter select {
  background: #262626;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  line-height: 15px;
  border: 2px solid #333333;
  padding: 6px 8px;
  outline-width: 0;
  border-radius: 8px;
}

.plinko-game-tables .tab-section {
  padding: 25px 0px;
}

@media screen and (max-width: 991px) {
  .plinko-game-tables .nav-tabs {
    width: 100%;
  }
  .plinko-game-table-filter {
    display: none !important;
}
}

.nav-link.disabled {
  color: #888 !important;
}
